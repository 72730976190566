
import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Notification } from "@/models/notification.interface";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { StyleCheck } from "@/mixins/style-check";
import EmailChecker from "@/components/Input/EmailChecker.vue";

@Component({ components: { EmailChecker } })
export default class ChangeEmail extends mixins(
  Navigation,
  FormValidations,
  StyleCheck
) {
  $refs!: {
    changeEmailForm: HTMLFormElement;
  };
  user = {
    email: "",
  };
  loading = false;
  userValidations = {
    email: false,
    loadingEmail: false,
  };
  private get image(): string {
    let logo = this.$store.getters["system/getLogo"];
    if (logo) {
      return logo.url;
    } else {
      return "";
    }
  }

  @Watch("language")
  resetValidations() {
    this.resetFormValidations([this.$refs.changeEmailForm]);
  }

  private get context(): string {
    return this.$store.getters["authentication/getContext"];
  }

  emailTimer = 0;

  @Watch("user.email")
  async verifyEmail(value: string) {
    const emailRegex = new RegExp(
      `^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$`
    );

    if (this.user.email && emailRegex.test(value as string)) {
      this.userValidations.loadingEmail = true;
      clearTimeout(this.emailTimer);
      this.emailTimer = setTimeout(async () => {
        let exists = await this.$store
          .dispatch("authentication/userExists", {
            email: value,
            alias: "",
          })
          .catch(() => {
            const Error: Notification = {
              message: this.$tc("SignUp.fetchError.emailExists"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          });
        this.userValidations.email = exists;
        this.userValidations.loadingEmail = false;
      }, 500);
    }
  }
  private async submit() {
    if (this.$refs.changeEmailForm.validate()) {
      this.loading = true;
      await this.$store
        .dispatch("authentication/changeEmail", {
          email: this.user.email,
          token: this.$route.params.token,
        })
        .then(() => {
          let message: string = this.$tc("ChangeEmail.success");
          const SuccessNotification: Notification = {
            message: message,
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            persistent: true,
            error: false,
          };

          this.$store.dispatch(
            "notifications/showNotification",
            SuccessNotification
          );
          this.loading = false;
          this.$store.dispatch("authentication/logOut");
          this.navigate("/email-sent");
        })
        .catch(() => {
          let errorMessage: string = this.$tc("ChangeEmail.error");
          const Error: Notification = {
            message: errorMessage,
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);

          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }
}
